<template>
  <div v-if="checkoutTemplate" class="custom-amount-form menu-form">
    <Header :withMerchant="false" />
    <section class="main successful-payment">
      <div class="order-check">
        <h2 class="order-check__title">
          {{ checkoutTemplate.orderNumber }}
        </h2>

        <div class="order-check__number">{{ payErrorData.invoiceNumber }}</div>

        <div class="order-check__message">
          <p>Ваша оплата не прошла :(</p>
          <p v-if="paymentResult.message">
            {{ paymentResult.message }}
          </p>
        </div>
      </div>

      <button @click="$router.back()" class="order-check__try-again">
        ←
        {{ tryAgainButton }}
      </button>
    </section>
  </div>
</template>

<script>
import checkoutResult from "@/mixins/checkoutResult";
import { mapGetters } from "vuex";
import { getBlock } from "@/store/modules/menu";
import { Block } from "@/constants";
import setRoute from "@/mixins/setRoute";

export default {
  mixins: [checkoutResult, setRoute],
  computed: {
    ...mapGetters(["pageTemplate", "route"]),
    tryAgainButton() {
      return this.checkoutTemplate?.retryButton?.find(
        (item) => item.name === "text"
      ).value;
    },
    checkoutTemplate() {
      return {
        orderNumber: getBlock(
          this.pageTemplate,
          this.route.current,
          Block.OrderNumberBlock
        )?.body.find((item) => item.name === "label").value,
        resultText: getBlock(
          this.pageTemplate,
          this.route.current,
          Block.ResultTextBlock
        )?.body.find((item) => item.name === "result_text").value,
        retryButton: getBlock(
          this.pageTemplate,
          this.route.current,
          Block.RetryButtonBlock
        )?.body,
      };
    },
  },
};
</script>
